import React from "react";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import BlogsPosts from "../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../component/Blog/ShareLinks";
import AuthorAvatar from "../component/Blog/AuthorAvatar";
import CommonParagraph from "../component/Blog/CommonParagraph";
import Heading from "../component/Blog/Heading";
import BlogLink from "../component/Blog/BlogLink";
import { blogList } from "../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/del-cerro-a-hidden-gem-for-outdoor-enthusiasts"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <p className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]">
                  By {blogData[0].author}
                </p>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../images/The Selby Team_Homebuying in San Diego_ Everything You Need to Know About Closing Costs.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <CommonParagraph>
                While San Diego neighborhoods like La Jolla and Pacific Beach typically hog the
                spotlight, Del Cerro is quickly climbing the list of local favorites—particularly
                for those who crave a wide selection of outdoor activities.
              </CommonParagraph>
              <CommonParagraph>
                Located in the East County region of the city, natural attractions surround this
                hidden gem of a community, yet only 11 miles from downtown San Diego. It’s the
                perfect compromise for those who want easy access to nature without giving up urban
                amenities.
              </CommonParagraph>
              <CommonParagraph>
                Here’s a local insider’s view of what makes this community ideal for nature lovers.
              </CommonParagraph>
              <Heading type="h2">Mission Trails Regional Park</Heading>
              <CommonParagraph>
                The beauty of San Diego is that despite its population density, you can still find
                over 7,000 open acres of unspoiled land to play in.
              </CommonParagraph>
              <CommonParagraph>Welcome to Mission Trails Regional Park.</CommonParagraph>
              <CommonParagraph>
                Whether you’re looking for a stroll or are a seasoned hiker who prefers a
                challenging backcountry route, the park’s extensive trail network offers a pathway
                for everyone.
              </CommonParagraph>
              <CommonParagraph>
                But this is just the tip of the iceberg. The park also offers rock climbing areas
                and a visitor center with a growing list of educational programs. Plus, it’s only a
                stone’s throw away from Lake Murray, which we’ll explore below.
              </CommonParagraph>
              <Heading type="h2">Lake Murray</Heading>
              <CommonParagraph>
                Adjacent to Mission Trails Park, Lake Murray Reservoir is packed with Florida-strain
                largemouth bass, bluegill, channel catfish, black crappie, and trout. The lake is
                open for shore fishing and private boats—or you can rent a boat from the on-site
                concession stand and spend a relaxing day on the water.
              </CommonParagraph>
              <CommonParagraph>
                But Lake Murray isn’t just for anglers. The reservoir is surrounded by a three-mile
                paved path, ideal for walking, biking, or rollerblading. Along the way, you’ll enjoy
                stunning views of the lake and nearby mountains, with plenty of bird and wildlife
                spotting opportunities.
              </CommonParagraph>
              <Heading type="h2">Cowles Mountain</Heading>
              <CommonParagraph>
                At 1,593 feet, Cowles Mountain is San Diego’s highest point. The main trail to the
                summit, which starts at the Cowles Mountain trailhead off Golfcrest Drive, is a
                three-mile round-trip hike. The higher you climb, the more spectacular the views
                become. On a clear day, you’ll enjoy panoramic views of downtown San Diego, the
                Pacific Ocean, and even Mexico.
              </CommonParagraph>
              <CommonParagraph>
                While the 950-foot elevation gain may be moderately challenging, the rewards are
                well worth the effort. If you’d prefer to climb rather than walk, the mountain has
                several climbing areas, including Kwaay Paay Peak and Pyles Peak.
              </CommonParagraph>
              <Heading type="h2">Del Cerro Park</Heading>
              <CommonParagraph>
                Step out your front door and into Del Cerro Park. While this 4.8-acre park could
                never compete with Mission Trails, it does pack a lot of amenities and charm into a
                compact space.
              </CommonParagraph>
              <CommonParagraph>
                Here, you’ll find an expansive lawn—perfect for picnics, stretching your legs after
                a long day at work, or sprawling out on a blanket with a good book. The park also
                offers a playground, basketball courts, picnic tables, and many ongoing community
                events like outdoor movie nights, holiday celebrations, and more.
              </CommonParagraph>
              <Heading type="h2">Nearby Beaches</Heading>
              <CommonParagraph>
                Del Cerro, San Diego may be an “inland neighborhood,” but when you’re in San Diego,
                the ocean is never far. Mission Beach and Pacific Beach are only a 20-25 minute
                drive. La Jolla and Del Mar, known for their rugged cliffs, coves, and marine life,
                are only a 30-minute drive to the north.
              </CommonParagraph>
              <CommonParagraph>
                Want to escape to North County for laid-back beach vibes? The beaches at Encinitas,
                Carlsbad, and Oceanside are worth the 45-minute drive.
              </CommonParagraph>
              <Heading type="h2">
                Want to Live Life Outdoors? Live in Del Cerro, San Diego.{" "}
              </Heading>
              <CommonParagraph>
                As local experts with deep community roots, we know that a neighborhood is much more
                than a collection of houses—it’s where you make memories that will last a lifetime.
                That’s why we’re passionate about showcasing hidden gems like Del Cerro. If you’re
                looking for the perfect mix of convenience, quiet, and access to nature, we have{" "}
                <BlogLink
                  url="https://selbysellssd.com/san-diego-communities/del-cerro-homes-for-sale"
                  text="plenty of homes to choose from"
                />
                . Let us help you find yours today. <ContactSlideoutLink text="Contact us now!" />
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
